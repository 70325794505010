<template>
<div style="text-align: left">
  <Card style="height:100%">
    <div class="search-con search-con-top">
      <Input clearable placeholder="输入通知名 搜索" class="search-input" v-model="queryinfo.name"
             @on-enter="namechange"
             @on-change="namechange"/>
      <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      <slot name="new_btn">
        <Button type="primary" class="search-btn"  @click="showaddreceivermodel">新建</Button>
      </slot>
    </div>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" ></Table>
    </div>
    <br />
    <template>
      <Page :current="queryinfo.page_num"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Drawer
        :closable="false"
        v-model="showaddreceiver"
        title="请添加告警通知"
        width="800">
      <Form ref="formValidate" :model="formDynamic" :rules="ruleformDynamic" :label-width="80">
        <FormItem label="名称" prop="name" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.name" placeholder="请输入通知名"></Input>
        </FormItem>
        <FormItem label="邮箱" prop="email" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.email" placeholder="请输入邮箱地址"></Input>
        </FormItem>
        <FormItem label="微信" prop="wechat" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.wechat" placeholder="请输入wechat"></Input>
        </FormItem>
        <FormItem>
          <Button @click="addform('formValidate')" type="primary">确定</Button>&nbsp;
          <Button @click="addcancel">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
    <Modal
        v-model="delmodel"
        title="确定删除该通知"
        @on-ok="okdelete"
        @on-cancel="delmodel = false">
      <p>正在进行删除操作</p>
      <p>删除通知： {{delname.name}}</p>
    </Modal>
  </Card>
</div>
</template>

<script>
import {get,post,del,getAlertAPIUrl} from "@/api/http.js";
export default {
  name: "receivers",
  data () {
    return {
      delname: {
        id:'',
        name:'',
      },
      delmodel: false,
      formDynamic: {
        name:"",
      },
      ruleformDynamic: {
        name: [
            { required: true, message: '名称不能为空'}
        ],
      },
      showaddreceiver: false,
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '通知名', key: 'name'},
        {title: '邮件通知人', key: 'email',
          render: (h,{row}) => {
            return h('div',row.email[0].to)
          }
        },
        {title: '微信机器人', key: 'wechat',
          render: (h,{row}) => {
            return h('div',row.wechat[0].url)
          }
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 |'),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }
      ],
      data: [],
      total: 0,
      pagesizeopts: [10, 30, 50, 100],
      queryinfo: {
        page_num: 1,
        page_size: 10,
        name:"",
      },
    }
  },
  methods: {
    showaddreceivermodel () {
      this.formDynamic =  {}
      this.showaddreceiver = true
    },
    namechange () {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getreceivers(this.queryinfo)
    },
    okdelete () {
      const url = getAlertAPIUrl(`/api/v1/receivers?ids=${this.delname.id}`);
      del(url).then((resp)=>{
        if (resp["code"]=== 200){
          this.getreceivers(this.queryinfo);
          this.$Message.success('删除成功');
        } else {
          this.$Message.error("删除失败，请稍后再试");
        }
      })
    },
    getreceivers(queryinfo) {
      const url = getAlertAPIUrl("/api/v1/receivers");
      get(url,queryinfo).then((resp)=>{
        if (resp["code"] === 200){
          this.total = resp["data"]["total"];
          if (resp["data"]["items"]) {
            this.data = resp["data"]["items"];
          } else {
            this.data = [];
          }
        } else {
          this.$Message.error("请求失败，请稍后再试");
        }
      })
    },
    changepage (page) {
      this.queryinfo.page_num = page;
      this.getreceivers(this.queryinfo);
    },
    addcancel () {
      this.showaddreceiver = false;
    },

    addreiver(data){
      const url = getAlertAPIUrl("/api/v1/receivers");
      post(url,data).then((resp)=>{
        if (resp.code===200){
          this.$Message.success('Success!');
          this.showaddreceiver = false;
          this.getreceivers(this.queryinfo);
        } else {
          this.$Message.error("请稍后再试");
        }
      }).cache((e)=>{
        this.$Message.error("请稍后再试");
      })
    },

    showupdatedrawer(row) {
      this.showaddreceiver = true
      this.formDynamic.id = row.id
      this.formDynamic.name =  row.name
      this.formDynamic.wechat = row.wechat[0].url
      this.formDynamic.email = row.email[0].to
      console.log(row)
    },

    isdelete(row){
      this.delmodel = true
      this.delname = {id:row.id,name:row.name}
    },

    addform (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          const obj={
            name:this.formDynamic.name,
            email:[{to:this.formDynamic.email}],
            wechat:[{url:this.formDynamic.wechat}]
          }
          if (this.formDynamic.id) {
            obj.id = this.formDynamic.id
          }
          this.addreiver(obj)
        } else {
          this.$Message.error('请完善表弟信息');
        }
      })
    },
    changepagesize(pagesize) {
      this.queryinfo.page_size = pagesize
      this.getreceivers(this.queryinfo)
    },
  },
  created() {
    this.getreceivers(this.queryinfo)
  },
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
